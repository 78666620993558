.container {
    display: flex;
    align-items: center;
}

.label_red {
    padding: 4px 8px 2px 8px;
    font-size: 16px;
    font-weight: 900;
    color: #dd003f;
    background-color: #02050E;
    border: 2px solid #93002a;
    border-right: 0;
    border-radius: 8px 0 0 8px;
    box-shadow: 0 2px 4px 2px rgba(164, 0, 0, 0.418);
}

.image_red {
    background-color: #93002a;
    background-image: url("fire.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 38px;
    height: 38px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 2px rgba(31, 0, 37, 0.989);
}

.label_violet {
    padding: 4px 8px 2px 8px;
    font-size: 16px;
    font-weight: 900;
    color: #b100f1;
    background-color: #02050E;
    border: 2px solid #680095;
    border-right: 0;
    border-radius: 8px 0 0 8px;
    box-shadow: 0 2px 4px 2px rgba(137, 0, 164, 0.418);
}

.image_violet {
    background-color: #680095;
    background-image: url("thumb.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 8px;
    width: 38px;
    height: 38px;
    box-shadow: 0 2px 4px 2px rgba(31, 0, 37, 0.989);
}

.image_yellow {
    background-color: #FFE500;
    background-image: url("sale.svg");
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    box-shadow: 0 2px 4px 2px rgba(31, 0, 37, 0.989);
    padding: 10px;
    box-sizing: border-box;
}

.label_yellow {
    padding: 6px 8px 2px 8px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 800;
    color: #FFE500;
    text-transform: uppercase;
    background-color: #02050E;
    border: 2px solid #FFE500;
    border-right: 0;
    border-radius: 8px 0 0 8px;
    box-shadow: 0 2px 4px 2px rgba(137, 0, 164, 0.418);
}