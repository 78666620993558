.install_game {
    transition: 0.2s;
    margin: 20px;
}

.game_title_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    transition: 0.2s;
}

.install_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px 0 10px 0;
    gap: 10px;
}

.game_title_container h1 {
    font-size: 32px;
    margin: 0;
}

.layout_gallery {
    margin-right: -20px;
}

.benefits_text {
    font-size: 28px;
    color: floralwhite;
    font-weight: 700;
    margin: 0;
    padding-top: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.approved_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.approved_title p {
    color: #51b55a;
    font-size: 18px;
    font-weight: 800;
    margin: 0;
    line-height: 20px;
    padding-top: 5px;
}

.install_btn {
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 14px 70px 14px 70px;
    background: linear-gradient(180deg, #008033 0%, #006428 100%);
    border-radius: 9px;
    color: white;
    font-size: 24px;
    font-weight: 600;
    transition: 0.10s;
    cursor: pointer;
}

.install_btn:hover {
    box-shadow: 0 6px 20px 10px #003707;
    background-color: #36ab42;
}

.install_btn:focus {
    box-shadow: 0 6px 20px 10px #001f04;
    background-color: #1d6f25;
}

.benefits_container {
    background-color: #0a0d15;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 14px 20px 14px 20px;
    flex-wrap: nowrap;
    gap: 40px;
    margin-top: 20px;
}

.benefits {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    text-align: center;
    transition: 0.2ms;
}

.standart_text {
    font-size: 16px;
    color: #727da4;
}

.about_container h2 {
    font-size: 24px;
    color: #6883d3;
}

.about_container {
    max-width: 900px;
}

.instruction_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    flex-grow: 1;
    margin-top: 20px;
    margin-bottom: 20px;
}

.instruction_item {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    background-color: #0a0d15;
    width: 100%;
}

.instruction_item_3 {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    background-color: #0a0d15;
    width: 100%;
    grid-column: 1/ -1;
}

.link_text {
    font-size: 18px;
    color: floralwhite;
    margin: 10px;
}

.contacts_bot {
    padding: 20px 0 20px 0;
}

.number {
    background-color: #0a200c;
    font-size: 32px;
    font-weight: 600;
    color: floralwhite;
    padding: 10px 0 0 20px;
}

.description {
    font-size: 20px;
    font-weight: 300;
    padding: 20px;
    color: floralwhite;
    text-align: center;
}

.question {
    margin-right: 10px;
    margin-bottom: 2px;
}

.title_instruction {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.instruction h1 {
    font-size: 24px;
    margin: 0 0 10px 0;
}

.install_container_bot {
    background-color: #0a0d15;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    padding: 14px 20px 14px 20px;
    flex-wrap: nowrap;
    gap: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.description_aproved {
    font-size: 20px;
    font-weight: 100;
    padding-left: 10px;
    color: floralwhite;
}

@media (min-width: 900px) {
    .instruction_item_3 {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        border-radius: 8px;
        overflow: hidden;
        background-color: #0a0d15;
        width: 100%;
    }
    .instruction_container {
        display: flex;
        grid-template-columns: repeat(300px, 1fr);
        gap: 10px;
        flex-grow: 1;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media (max-width: 700px) {
    .instruction_container {
        display: flex;
        flex-direction: row;
        gap: 20px;
        flex-wrap: wrap;
    }
    .install_container_bot {
        background-color: #0a0d15;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 14px 20px 14px 20px;
        flex-wrap: nowrap;
        gap: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media (max-width: 540px) {
    .game_title_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
    }
    .install_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 10px 0 10px 0;
        gap: 10px;
    }
    .install_btn {
        appearance: none;
        border: 0;
        padding: 14px 70px 14px 70px;
        background-color: #31943b;
        border-radius: 8px;
        color: aliceblue;
        font-size: 24px;
        font-weight: 600;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.50);
        box-shadow: inset 0 0 15px 6px #22802b;
        box-shadow: 0 0 0 0 #004909;
        transition: 0.10s;
    }
    .benefits {
        text-align: center;
        transform: scale(0.8);
        width: 0;
    }
    .gallery_item {
        width: calc(142px + 12em);
        height: calc(257px + 12em);
        border-radius: 8px;
        border: 2px solid #454545;
    }
}