a.navbutton {
    display: block;
    padding: 14px;
    text-align: center;

    font-size: 18px;
    font-weight: 600;
    color: white;
    text-decoration: none;

    background: #00650A;
    border-radius: 9px;
    background: linear-gradient(180deg, #008033 0%, #006428 100%);

    transition-duration: .25s;
}

a.navbutton:hover {
    opacity: 0.7;
}