/* PlayerSearchResult */
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    border-radius: 8px;
    background-image: url("check.png");
    background-position: calc(100% - 20px) 50%;
    background-repeat: no-repeat;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    border: 2px solid #00a16e;
}

.name {
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
}

/* PlayerResult */
.user_container {
    display: flex;
    justify-content: space-between;
    background-color: #002B2B;
    border-radius: 40px 8px 8px 40px;
    padding-top: 6px;
    padding-right: 6px;
    padding-bottom: 6px;
    width: 100%;
}

.similar_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    position: relative;
}

.similar_avatar {
    width: 56px;
    height: 56px;
    position: absolute;
    border-radius: 50px;
    border: 2px solid #00a16e;
}

.similar_name {
    margin-left: 70px;
    margin-top: 4px;
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
}

.edit_user {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #001F1F;
    color: #005959;
    font-size: 16px;
    font-weight: 600;
    border-radius: 9px;
    padding: 6px;
    transition: 0.3s;
    cursor: pointer;
}

.edit_user:hover {
    background-color: #005d5d;
    color: #008a8a;
    transition: 0.3s;
}

.check_icon {
    background-image: url("check.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
}

@media (max-width: 482px) {
    .user_container {
        display: flex;
        flex-direction: column;
        background-color: #002B2B;
        border-radius: 8px;
        padding: 6px;
        gap: 6px;
    }

    .similar_avatar {
        position: relative;
        width: 36px;
        height: 36px;
    }

    .similar_name {
        margin-left: 0;
        margin-top: 4px;
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
    }
}