.modal {
    position: fixed;
    display: flex;
    animation-iteration-count: inherit;
    animation: show_bg .2s forwards;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
}

@keyframes show_bg {
    from {
        background-color: rgba(0, 0, 0, 0);
    }
    to {
        background-color: rgba(0, 0, 0, 0.66);
    }
}

.modal_container {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: 0.6s;
}

.modal_body {
    position: relative;
    margin: auto;
    width: 100vw;
    max-width: 1064px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.modal_item {
    border-radius: 8px;
    border: 2px solid #454545;
    height: 90vh;
    object-fit: cover;
    transition: 0.2s;
    image-rendering: -moz-crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    image-rendering: crisp-edges;
    background-color: red;
    animation: item_show .2s forwards;
}

@keyframes item_show {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.arrow {
    display: flex;
    position: absolute;
    top: 50vh;
    align-items: center;
    width: 32px;
    padding: 32px;
    border-radius: 50px;
    background-color: #454545;
    height: 15px;
    background-image: url("open_arrow.png");
    background-position: center;
    background-repeat: no-repeat;
    border: solid #343434 2px;
    transition: 0.3s;
    cursor: pointer;
    z-index: 20;
}

.left {
    left: 40px;
    transform: rotate(90deg);
}

.right {
    right: 40px;
    transform: rotate(-90deg);
}

.arrow:hover {
    background-color: #6d6d6d;
}