.spinner {
    width: 74px;
    height: 74px;
    animation-name: rotation;
    animation: rotation 1.5s infinite ease;
    background-image: url("spinner.png");
}

.overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100vw;
    top: 0;
    left: 0;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.792);
    z-index: 100;
}

@keyframes rotation {
    0% {
        transform: rotate(-0deg)
    }
    100% {
        transform: rotate(-360deg)
    }
}