.logo {
    background-image: url("logo.png");
    width: 164px;
    height: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: 32px;
    margin-top: 8px;

    @media (max-width: 768px) {
        margin-left: 0;
    }
}
