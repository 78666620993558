.card {
    position: relative;
    background: linear-gradient(180deg, #111421 0%, #1D2338 100%);
    border: 1px solid #373F61;
    border-radius: 12px;
    padding: 10px 4px 4px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.2s;
}

.offer.card {
    background: linear-gradient(146.59deg, #401764 17.79%, #181D2F 63.98%);
    border: 1px solid #5B248A;
}

.card:hover {
    opacity: 0.7;
}

.sale.card {
    background: linear-gradient(146.59deg, #57361B 17.79%, #181D30 63.98%);
    border: 1px solid #714726;
}

.card>.sticker {
    position: absolute;
    right: 10px;
    top: 10px;
}

.card .value {
    font-size: 24px;
    font-weight: 700;
    color: azure;
    display: flex;
    align-items: center;
    justify-content: center;
}

.price {
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
}

.sale.price {
    color: #FFF;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: baseline;
    gap: 10px;
}

.old_price {
    font-size: 14px;
    font-weight: 700;
    color: #ffffff80;
    text-decoration: line-through;
    display: none;
}

.sale.old_price {
    display: block;
}

.icon {
    width: 19px;
    height: 18px;
    margin-right: 10px;
    margin-top: -4px;
}

.price_info {
    padding-top: 8px;
    font-weight: 600;
    font-size: 16px;
    color: #39476F;
}

.sale.price_info {
    color: #FFE500;
}

.choose_buy {
    position: relative;

    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    justify-content: center;

    height: 48px;

    font-size: 13px;
    font-weight: 700;
    line-height: 15px;
    color: #FFF;
    text-align: left;

    border-radius: 9px;

    transition: 0.2s;
    background: linear-gradient(180deg, #008033 0%, #006428 100%);
    cursor: pointer;
    border: none;
}

.sale.choose_buy {
    background: linear-gradient(180deg, #A75600 0%, #834300 100%);
}

.offer.choose_buy {
    background: linear-gradient(180deg, #7F26B1 0%, #601C86 100%);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.67), inset 0 1px 2px 1px #9C2EDA;
}

.saleTile {
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;

    height: 20px;
    padding-right: 5px;
    padding-left: 5px;

    font-size: 11px;
    font-weight: 800;
    line-height: 14px;
    color: #ffff;
    text-align: right;
    text-transform: uppercase;

    border-radius: 6px;
}


@media (max-width: 460px) {
    .card .value {
        font-size: 16pt;
    }
}