.container {
    width: 100%;
}

.header {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 600;
    color: #ffffff;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 0 0 16px 16px;
}

.item {
    width: 100%;
    color: #5971B8;
    font-weight: 600;
}

.left {
    width: 48%;
    box-sizing: border-box;
}
.right {
    margin-left: 25px;
    width: 48%;
    box-sizing: border-box;
}

.explanation {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    @media (max-width: 768px) {
        .left {
            width: 100%;
        }
        .right {
            width: 100%;
            margin-left: 0;
        }
    }
}