.header {
    background-color: #171E2F;
    border-radius: 16px 16px 0 0;
    padding: 10px 10px;
    font-size: 16px;
    font-weight: 600;
    color: #99ACEF;
}

.item {
    width: 100%;
}

.input {
    width: 100%;
    height: 40px;
    outline: none;
    border-style: none;
    background: #DADCF0/*!important*/;
    border-radius: 4px;
    color: #1E1E3A;
    padding: 10px;
    font-weight: 600;
    box-sizing: border-box;
    font-size: 17px;
}

/*
При заполнении поля автокомплитом webkit меняет цвета и игнорирует их переопределение.
Данным хаком мы бесконечно затягиваем применение этих цветов.
*/
.input:-webkit-autofill {
    transition: 9000s ease-in-out 0s;
}

.highlight {
    border: solid 1px darkorange;
}