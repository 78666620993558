.container {
    padding: 11px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    font-size: 18px;
    font-weight: 600;
    color: #d80000;
    background-color: rgb(90 0 39);
    border-radius: 8px;
    transition-duration: .25s;
}

.icon {
    background-image: url("exclaim.png");
    background-position: center;
    background-size: cover;
    min-width: 22px;
    height: 22px;
}

@media (max-width: 700px) {
    .container {
        flex-direction: column;
    }

    .container::before {
        transform: rotate(90deg);
    }
}