.price_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #1A274C;
    font-size: 22px;
    font-weight: 700;
    color: white;
}

.price {
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 16px;
}


.payform {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-auto-rows: minmax(auto);
    justify-items: stretch;
    align-items: stretch;
    background: linear-gradient(331.61deg, #0A183E 0%, #02050E 96.4%);
    border: 1px solid #1A274C;
    border-radius: 8px;
    gap: 20px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.priceinfo {
    display: flex;
    flex-direction: column;
}

.nominal_container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    border-bottom: 1px solid #1A274C;
    padding-bottom: 4px;
}

.icon {
    width: 19px;
    height: 18px;
    margin-right: 10px;
    margin-top: -4px;
    background-image: url("../../components/ChipsProduct/icon.png");
    background-position: center;
    background-size: contain;
}

.nominal_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2px;
    margin-left: 10px;
}

.title {
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    color: white;
}

.nominal_value {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 24px;
    font-weight: 700;
    color: white;
    margin-top: 2px;
}

.form {
    display: flex;
    flex-direction: column;
}

.input_container {
    display: flex;
    flex-direction: column;
}

.header {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 600;
    color: #ffffff;
}

.input {
    width: 100%;
    height: 40px;
    outline: none;
    border-style: none;
    background: #DADCF0;
    border-radius: 4px;
    color: #1E1E3A;
    font-weight: 500;
    font-size: 17px;
    padding: 10px;
    box-sizing: border-box;
}

.item {
    width: 100%;
}

.title_info {
    color: #5971B8;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.btn_buy {
    padding: 8px;
    font-weight: 600;
    width: auto;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    background: linear-gradient(180deg, #008033 0%, #006428 100%);
    border-radius: 9px;
    transition-duration: .25s;
    cursor: pointer;
    border: none;
}

.btn_buy:hover {
    opacity: 0.8;
}

@media (max-width: 768px) {
    .payform {
        grid-template-columns: 1fr;
    }
}