.icon_game {
    width: 160px;
    height: 160px;
    background-repeat: no-repeat;
    background-image: url("./icons/Icon_game_1.png");
}

.icon_valid {
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-image: url("./icons/valid_icon.png");
}

.icon_rate {
    width: 38px;
    height: 38px;
    background-repeat: no-repeat;
    background-image: url("./icons/rate_icon.png");
}

.icon_child {
    min-width: 35px;
    height: 38px;
    background-repeat: no-repeat;
    background-image: url("./icons/child_icon.png");
}

.icon_download {
    width: 77px;
    height: 104px;
    background-repeat: no-repeat;
    background-image: url("./icons/download_icon.png");
}

.icon_install {
    width: 92px;
    height: 67px;
    background-repeat: no-repeat;
    background-image: url("./icons/install_icon.png");
}

.icon_allow {
    width: 84px;
    height: 44px;
    background-repeat: no-repeat;
    background-image: url("./icons/allow_icon.png");
}

.icon_close {
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-image: url("./icons/close_icon.png");
}

.icon_star {
    margin-top: -5px;
    height: 27px;
    width: 25px;
    background-repeat: no-repeat;
    background-image: url("./icons/icon_star.png");
}

.icon_benefits {
    margin-bottom: 10px;
}

.icon_instruction {
    background-color: #0a200c;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 20px 0;
    height: 50px;
}