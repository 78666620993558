body {
    background-color: #111122;
    color: #777D88;
    font-family: "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif";
}

h1 {
    font-size: 32px;
    font-weight: 600;
    color: aliceblue;
}

a {
    text-decoration: none;
}

.shop-content {
    display: grid;
    grid-gap: 20px;
    //grid-template-columns: repeat(3, 1fr);
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    row-gap: 20px;
    padding: 20px;

    @media (max-width: 580px) {
    .title {
        text-align: center;
    }
}
}

@media (max-width: 960px) {
    .shop-content {
        //grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 482px){

    h1 {
        font-size: 20px;
        font-weight: 600;
        color: aliceblue;
    }
}

.card {
    position: relative;
    background-color: lightblue;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    margin: 20px;
    width: 200px;
}

.offer-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    //position: absolute;
    //left: 50%;
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
}

.card-content {
    margin-top: 20px;
}
