#image_gallery {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
    gap: 20px;
    padding: 20px 0 20px 0;
}

#image_gallery::-webkit-scrollbar-thumb {
    background-color: #484f69;
    border-radius: 8px;
    transition: 0.2s;
}

#image_gallery::-webkit-scrollbar {
    width: 7px;
    height: 10px;
    border-radius: 8px;
    background-color: #23232d;
}

#image_gallery::-webkit-scrollbar-thumb:hover {
    width: 7px;
    height: 10px;
    border-radius: 8px;
    background-color: #6883d3;
}

.image_gallery_item{
    border-radius: 8px;
    border: 2px solid #454545;
    width: 142px;
    height:257px ;
    transition: 0.2s;
    cursor: pointer;
}

@media (max-width: 520px) {
    .image_gallery_item {
        width: calc(142px + 12em);
        height:calc(257px + 12em) ;
        border-radius: 8px;
        border: 2px solid #454545;
        pointer-events: none;
    }
}