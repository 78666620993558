.form {
    display: flex;
    align-items: stretch;
    width: 100%;
}

.form input {
    width: 100%;
    height: 40px;
    outline: none;
    border-style: none;
    background: #DADCF0/*!important*/;
    border-radius: 4px 0 0 4px;
    color: #1E1E3A;
    padding: 10px;
    font-weight: 600;
    box-sizing: border-box;
    font-size: 17px;
}

.form input:disabled {
    color: #666666;
}

.form button {
    color: white;
    font-size: 18px;
    background: #00A610;
    border: none;
    border-radius: 0px 4px 4px 0px;
    height: 40px;
    transition-duration: .25s;
    box-sizing: border-box;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: 600;
}

.form button:disabled {
    color: #ffffff;
}

.form button:enabled:hover {
    background: #25418F;
}

.highlight input {
    border: solid 1px rgb(255, 0, 0);
}

.highlight button {
    border: solid 1px darkorange;
}