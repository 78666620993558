a.navlink {
    display: flex;
    align-items: center;
    padding: 11px;
    text-align: center;
    gap: 5px;
    font-size: 18px;
    font-weight: 600;
    color: #5B6E96;
    text-decoration: none;
    border-radius: 9px;
    transition-duration: .25s;
}

a.navlink:hover {
    background-color: #2E2F32;
}