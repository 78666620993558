.header_container {
    width: 100%;
    height: 65px;
}

.header {
    background-color: #000000;
    position: relative;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    z-index: 20;
}

.right {
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: .25s;
    gap: 10px;
}

.navlist {
    display: flex;
    transition-duration: .25s;
}

#menu_toggle {
    display: none;
}

.menu_btn {
    align-items: center;
    position: absolute;
    z-index: 1;
    /* центрируем содержимое кнопки */
    top: 20px;
    left: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: none;
    direction: unset;
    background-image: url("Burger.png");
}

#menu_toggle:checked~.menu_btn {
    width: 27px;
    height: 26px;
    background-image: url("Close.png");
    transition-duration: .25s;
}

#menu_toggle:checked~.navlist {
    display: flex;
    height: auto;
    animation-name: show_menu;
    animation: show_menu .3s ease;
    top: 0;
}

@keyframes show_menu {
    0% {
        opacity: 0;
        margin-top: -10px;
    }
    100% {
        opacity: 1;
        margin-top: 0;
    }
}

@media (max-width: 760px) {
    .header {
        background-color: #000000;
        padding: 20px 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .navlist {
        display: none;
        width: 100%;
    }
    .menu_btn {
        display: block;
        transition-duration: .25s;
    }
    .right {
        flex-direction: column;
        margin-top: 20px;
        gap: 10px;
    }
    .header_container {
        width: 100%;
        height: 130px;
    }
}