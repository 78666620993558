.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #000000;
    font-size: 14px;
    font-weight: 100;
    padding-top: 5px;
    padding-bottom: 20px;
}

.footer p {
    margin: 20px;
    padding: 0;
    text-align: center;
}

.footer ul {
    display: flex;
    justify-content: center;
}

.navlist {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

@media (max-width: 700px) {
    .navlist {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}