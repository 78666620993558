html, body {
    flex: 1;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

.layout-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.layout-wrapper > main {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 96vw;
    max-width: 1068px;
    flex: 1 0 auto;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 1;
    align-self: stretch;
}