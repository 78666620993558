.selectform {
    display: flex;
    grid-auto-rows: minmax( auto);
    justify-items: stretch;
    align-items: stretch;
    background: linear-gradient(331.61deg, #0A183E 0%, #02050E 96.4%);
    border: 1px solid #1A274C;
    border-radius: 8px;
    gap: 20px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}


/* navigation step */

.purchase_stages_container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 120px;
}

.purchase_stages {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #111122;
    box-shadow: 0 6px 5px rgba(0, 0, 0, 0.45);
    border-bottom: 1px solid #1A274C;
    padding: 0 15px 10px 15px;
}

.numbers_container {
    display: flex;
    flex-direction: row;
    max-width: 1058px;
    width: 100%;
}

.fade_container {
    width: 50%;
}

.titles_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: 1058px;
    gap: 10px;
}

.number_border {
    color: #5971B8;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    width: 220px;
}

.number_border_active {
    color: #34A23F;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    width: 220px;
}

.number_border_completed {
    color: #3F3F66;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    width: 220px;
}

.number_arrow {
    display: flex;
    font-weight: 700;
    font-size: 32px;
    align-items: center;
    text-align: center;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
}

.arrow_line {
    right: 0;
    height: 2px;
    width: 100%;
    background-color: #5971B8
}

.img_arrow {
    background-image: url("arrow.png");
    min-width: 9px;
    height: 16px;
}

.title_item {
    background: #1E1E3A;
    color: #5971B8;
    font-weight: 700;
    font-size: 16px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #070d30;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title_item_active {
    background: linear-gradient(180deg, #008033 0%, #006428 100%);
    color: white;
    font-weight: 700;
    font-size: 16px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #005708;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title_item_completed {
    background: #1E1E3A;
    color: #3F3F66;
    font-weight: 700;
    font-size: 16px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #070d30;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: 0.2s;
}

.title_item_completed:hover {
    border: 1px solid #2a3781;
}

@media (max-width: 478px) {
    .title_item {
        font-size: 14px;
        padding: 6px 4px 4px 2px;
        transition: 0.3s;
    }
    .title_item_active {
        font-size: 14px;
        padding: 6px 4px 4px 2px;
        transition: 0.3s;
    }
    .title_item_completed {
        font-size: 14px;
        padding: 6px 4px 4px 2px;
        transition: 0.3s;
    }
}