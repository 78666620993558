.collapse {
    width: 100%;
    background-color: #0c0d11;
    overflow: hidden;
    border-radius: 8px;
}

.collapse_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #222531;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
}

.collapse_body {
    max-height: 0;
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    opacity: 0;
}

.collapse_body.active {
    max-height: 800px;
    transition: 0.6s cubic-bezier(0.65, 0, 0.35, 1);
    opacity: 1;
}

.collapse_header:hover {
    background-color: #3b4157;
}

.collapse_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.icon_question {
    min-width: 20px;
    height: 20px;
    background-image: url("question_icon.png");
}

.collapse_text {
    margin-top: 5px;
    color: #dce3ff;
    font-size: 18px;
}

.btn_open_arrow {
    width: 16px;
    height: 16px;
    transition-duration: .25s;
    padding: 8px;
    cursor: pointer;
    background-image: url("open_arrow.png");
    background-repeat: no-repeat;
    background-position: center;
    transform: rotate(0);
    transition: 0.2s;
}

.btn_open_arrow.active {
    transform: rotate(180deg);
}

.card {
    padding: 20px 20px 35px 20px;
    border-bottom: solid #222531 1px;
}